.TriggerUsage {
  width: 90%;
  margin: auto;
  margin-top: 3rem;
  .usageTitle {
    font-size: 2rem;
    font-weight: 600;
    color: var(--text-secondary);
    text-align: center;
  }
}
.usageGrid {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  align-items: stretch;
  padding: 2rem 0;
}

.usageCard {
  background: #dffdfd;
  width: 80%;
  box-shadow: 3px 3px 5px 1px #00000023;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  padding: 1rem;
  &:not(:first-child) {
    justify-self: center;
  }
  &:last-child {
    justify-self: flex-end;
  }
  &.lg {
    width: 100%;
    flex-direction: row;
  }
  .usageImage {
    padding-bottom: 1rem;
    img {
      height: 3.5rem;
    }
  }
  .usage {
    color: var(--text-secondary);
    text-transform: capitalize;
    font-weight: 600;
    text-align: center;
    font-size: 1.25rem;
    &.lg {
      padding-left: 1rem;
    }
  }
}

@media screen and (max-width: 1366px) {
  .usageCard {
    width: 90%;
  }
}

@media screen and (max-width: 991px) {
  .usageGrid {
    grid-template-columns: repeat(2, 1fr);
  }

  .usageCard {
    width: 100%;
    margin: 10px 0;
    &.lg {
      flex-direction: column;
    }
    &:first-child {
      justify-self: center;
    }
    &:last-child {
      justify-self: center;
    }
  }
}

@media screen and (max-width: 480px) {
  .usageGrid {
    grid-template-columns: repeat(1, 1fr);
  }
  .usageCard {
    width: 100%;
    margin: 10px 0;
  }
}
