.coverImageWrapper {
  height: 45vh;
  position: relative;
  overflow: hidden;
  z-index: 1;

  .coverImage {
    position: absolute;
    height: 45rem;
    z-index: -1;
    width: 100%;
    object-fit: cover;
  }
  .overlay {
    position: absolute;
    background-color: rgba(0, 0, 0, 0.507);
    z-index: 2;
    width: 100%;
    height: 45vh;
  }
}

.pageWrapper {
  margin: auto;
  width: 90%;
  // background: var(--bg-ternary);
}

.coverHeading {
  position: absolute;
  z-index: 10;
  top: 10;
  right: 0;
  left: 0;
  bottom: 2.5rem;

  h2 {
    text-align: center;
    font-weight: 700;
    font-size: 2.5rem;
    color: var(--text-light);
    text-shadow: 1px 2px 3px rgb(20, 20, 20);
  }
}

.headSection {
  top: 0;
  z-index: 10;
  position: relative;
  .headDescription {
    img {
      float: left;
    }
    padding: 1rem;
    box-shadow: 0 2px 30px #b8b8b855;
    border-radius: 0.5rem;
    background: white;
    min-height: 170px;
    text-align: justify;
  }
  h2 {
    text-align: center;
    font-weight: 700;
    font-size: 2.5rem;
    color: var(--text-light);
    text-shadow: 1px 2px 3px rgb(20, 20, 20);
  }
}

@media screen and (max-width: 1366px) {
  .headSection {
    top: 0rem;
    h2 {
      font-size: 2rem;
    }
  }
}

.msRow {
  display: flex;
  align-items: center;
  margin-bottom: 5rem;
  margin-top: 5rem;
  .msCol {
    // padding: 0 1.5rem;
    // width: 100%;
    display: flex;
    justify-content: center;
  }
  &.left {
    flex-direction: row;
  }
  &.right {
    flex-direction: row-reverse;
  }
}

.videoWrapper {
  position: relative;

  .sectionImage {
    height: 350px;

    position: relative;
  }

  @media screen and (max-width: 768px) {
    .sectionImage {
      height: 300px;
    }
  }
  @media screen and (max-width: 576px) {
    .sectionImage {
      height: 250px;
    }
  }
  @media screen and (max-width: 480px) {
    .sectionImage {
      height: 200px;
    }
  }
  @media screen and (max-width: 375px) {
    .sectionImage {
      height: 150px;
    }
  }

  .video {
    height: 420px;
    position: relative;
    z-index: 12;
    border: 1rem solid black;
    border-radius: 1.5rem;
    background: white;
    &.left {
      box-shadow: 1rem 1rem 1rem #97979793;
    }
    &.right {
      box-shadow: -1rem 1rem 1rem #97979793;
    }
  }
  .videoBorder {
    position: absolute;
    height: 450px;
    width: 100%;
    border: 0.5rem solid var(--bg-secondary);
    border-radius: 1.25rem;
    z-index: 11;
    top: 20px;
    &.left {
      left: -30px;
    }
    &.right {
      right: -30px;
    }
  }
}

.contentWrapper {
  width: 80%;
  .content {
    text-align: justify;
    margin-top: 3rem;
    font-size: 1.15rem;
  }
}

@media screen and (max-width: 1600px) {
  .videoWrapper {
    .video {
      height: 380px;
    }
    .videoBorder {
      height: 400px;
    }
  }
}


@media screen and (max-width: 1366px) {
  .videoWrapper {
    .video {
      height: 300px;
    }
    .videoBorder {
      height: 320px;
    }
  }
  .msRow {
    margin-bottom: 1.5rem;
    &.left,
    &.right {
      flex-direction: column;
    }
  }

  .sectionImage {
    margin-bottom: 3rem;
  }
}

@media screen and (max-width: 1024px) {
  .videoWrapper {
    .video {
      height: 250px;
    }
    .videoBorder {
      height: 270px;
    }
  }
  .contentWrapper {
    .titleWrapper {
      font-size: 1.25rem;
    }
    .content {
      font-size: 1rem;
    }
  }
}

@media screen and (max-width: 1023px) {
  .msRow {
    margin-bottom: 1.5rem;
    &.left,
    &.right {
      flex-direction: column;
    }
  }
  .videoWrapper {
    width: 100%;
    .video {
      height: 100%;
      width: 100%;
    }
    .videoBorder {
      display: none;
    }
  }
  .contentWrapper {
    width: 100%;
  }
  .paraSection {
    padding: 0 4rem !important;
  }
}

.paraSection {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 0 6rem;
}

.paraHeadingPart {
  color: var(--color-primary);
}

.paraText {
  font-size: 1.2rem;
  text-align: justify;
}
.paraHeading {
  text-transform: uppercase;
  font-size: 2rem;
  font-weight: 700;
  margin-bottom: 2rem;
}

.slider {
  margin-bottom: 5rem;
  width: 600px;
}

.sliderImage {
  height: 100%;
  width: 100%;
  display: block;
}

.comingSoonText {
  font-size: 3rem;
  margin: 3rem 0;
  font-weight: 600;
  display: flex;
  width: 100%;
}

.talkToUSLink {
  color: var(--color-primary);
  font-weight: 600;



}
@media screen and (max-width: 576px) {
  .slider {
    width: 400px !important;
  }
}

@media screen and (max-width: 375px) {
  .slider {
    width: 300px !important;
  }
}
