.PageTalkToUsWrapper {
  display: flex;
  background: url("https://storage.googleapis.com/pzone-site-images/map.webp");
  background-repeat: no-repeat;
  background-size: cover;
  // background-attachment: fixed;
  min-height: 1150px;

  .textCol {
    // width: 60%;
    padding: 5rem;
  }
  .formCol {
    width: 40%;
    padding: 2rem;
  }
}

.head {
  font-size: 3.6rem;
  color: white;
  font-weight: 800;
}

.subHead {
  font-weight: 600;
  color: white;
}
.captcha {
  display: flex;
  justify-content: center;
  align-items: center;
}
.formWrapper {
  background: white;
  border-radius: 2rem;
  padding: 2rem;
  min-height: 75vh;
  width: 80%;
  box-shadow: 0 0 6px #04423d;
  position: relative;
  .formTitle {
    color: var(--text-secondary);
    text-transform: uppercase;
    font-size: 1.5rem;
    font-weight: 700;
    span:first-child {
      color: #7c7c7c;
    }
  }
}
.thanksScreen {
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(255, 255, 255, 0.993);
  border-radius: 2rem;
  min-height: 500px;
  flex-direction: column;
  padding: 0 2rem;
  box-shadow: 0 0 6px #04423d;
  .thanksWrapper {
    text-align: center;
    h1 {
      color: var(--text-secondary);
      font-size: 3.5rem;
      font-weight: 600;
    }
    span {
      font-style: italic;
    }
  }
}
.formLoader {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 120;
  background: linear-gradient(45deg, #7854153f, #2154876e);
  display: flex;
  border-radius: 1.5rem;
  justify-content: center;
  align-items: center;
  font-size: 2rem;
  color: white;
  font-weight: 800;
}


.phoneInput {
  display: grid;
  grid-template-columns: 100px 1fr;
  column-gap: 0.5rem; 
}

.submitBtn {
  width: 80%;
  border: none;
  margin: auto;
  border-radius: 2rem;
  background: var(--bg-gradient-secondary);
  color: var(--text-light);

  font-weight: 600;
  margin: 1rem 0;
  padding: 0.5rem 0;
}
.error {
  color: var(--text-danger);
  font-size: 0.85rem;
}
.preferences {
  color: rgb(151, 151, 151);
  padding: 1rem 0;
  .radioWrapper {
    display: flex;
    .radio {
      display: inline-flex;
      text-decoration: none;
      color: rgb(102, 102, 102);

      align-items: center;
      padding: 0.5rem 1rem 0.5rem 0;
      border: none;
      background: transparent;
      transition: 0.3s;
      label {
        pointer-events: none;
        padding: 0 0.5rem;
      }
      outline: none;
      &:focus {
        .mark {
          box-shadow: 0 0 2px 2px #13ff4e46, 0 0 2px 2px #15748146;
        }
        label {
          color: var(--text-secondary);
        }
      }
      .mark {
        height: 1.25rem;
        width: 1.25rem;
        background: var(--text-light);
        // margin: 0 0.5rem;
        border: 1px solid var(--bg-secondary);
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
        .check {
          height: 1rem;
          width: 1rem;
          background: var(--bg-secondary);
          border-radius: 50%;
        }
      }
      &:hover {
        color: var(--text-secondary);
        cursor: pointer;
      }
    }
  }
}

@media screen and (max-width: 1366px) {
  .PageTalkToUsWrapper {
    .textCol,
    .formCol {
      width: 50%;
    }
  }
  .thanksScreen {
    .thanksWrapper {
      h1 {
        font-size: 2.5rem;
      }
    }
  }
}

@media screen and (max-width: 1024px) {
  .PageTalkToUsWrapper {
    min-height: 800px;
    .textCol {
      display: none;
    }
    .formCol {
      width: 100%;
    }
  }
  .formWrapper {
    width: 50%;
    margin: auto;
  }
}

@media screen and (max-width: 768px) {
  .formWrapper {
    width: 100%;
  }
  .thanksScreen {
    .thanksWrapper {
      h1 {
        font-size: 1.5rem;
      }
    }
  }
}

