@mixin flex-between($align: stretch) {
  display: flex;
  justify-content: space-between;
  align-items: $align;
}

@mixin flex($direction: row, $justify: center, $align: center) {
  display: flex;
  justify-content: $justify;
  align-items: $align;
  flex-direction: $direction;
}

@mixin link-default($size: 1rem, $color: var(--text-secondary)) {
  display: inline-block;
  text-decoration: none;
  transition: transform 0.3s ease;
  color: $color;
  font-size: $size;
}

@mixin menu-link {
  @include link-default(1.15rem);
  padding: 0.25rem 1.25rem;
  text-transform: uppercase;

  &:hover {
    transform: scale(1.2);
    color: var(--hover-text-secondary);
    cursor: pointer;
  }
}
