.PagePrivacyPolicy {
  h1 {
    padding: 2rem 0;
    font-weight: 700;
    background: var(--bg-secondary);
    color: var(--text-light);
  }
  section {
    padding: 0.75rem 0;
    line-height: 2rem;
    h3 {
      color: rgb(68, 68, 68);
      font-size: 1rem;
      font-weight: 600;
    }
    p {
      color: rgb(49, 49, 49);
      font-size: 0.9rem;
    }
    ul {
      padding: 0;
      list-style: "-  ";
    }
  }
}
