@import "/src/styles/mixins.scss";
.headerWrapper {
  //   position: sticky;
  //   top: 0;
  position: relative;
  z-index: 20;
  //   background: var(--bg-secondary);
  .header {
    @include flex-between;
    // background: rgba(238, 238, 238, 0.664);
    background: var(--bg-primary);
    .logoWrapper {
      padding: 5px 35px;
      .image {
        height: 70px;
      }
      &.darkBG {
        background: var(--bg-secondary);
      }
    }
  }
}
.numbersWrapper {
  background: var(--bg-secondary);
  display: flex;
  justify-content: flex-end;
  padding: 0rem 0.75rem;
  .numbers {
    display: flex;
    align-items: center;

    a {
      font-size: 0.8rem;
      font-weight: 500;
      color: var(--text-light);
      text-decoration: none;
      display: inline-block;
      padding: 0 0.5rem;
      transition: 0.3s;
      &:hover {
        color: gold;
      }
    }
  }
}

@media screen and (max-width: 1365px) {
  .numbersWrapper {
    display: none;
  }
}

@media screen and (max-width: 767px) {
  .headerWrapper {
    .header {
      .logoWrapper {
        padding: 0.5rem 0.5rem;
        .image {
          height: 50px;
        }
      }
    }
  }
}
