.featuresGrid {
  //   display: grid;
  display: flex;
  justify-content: space-evenly;
  //   grid-template-columns: repeat(3, 1fr);
  margin-top: 5rem;
}

.gridCard {
  align-self: center;
  justify-self: center;
  .gridHeadImages {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    img {
      height: 15rem;
      width: 15rem;
    }
  }
  .gridText {
    text-align: center;
    max-width: 25rem;
    margin: auto;
  }
}

@media screen and (max-width: 991px) {
  .featuresGrid {
    flex-direction: column;
    margin-top: 3rem;
  }
}

@media screen and (max-width: 767px) {
  .featuresGrid {
    margin-top: 0;
    flex-direction: column;
  }
  .gridCard {
    margin: 1.5rem 0;
    .gridHeadImages {
      img {
        height: 12rem;
        width: 12rem;
      }
    }
  }
}
