.whoWeAreWrapper {
  position: relative;
  display: flex;
  .imagePanel {
    width: 52%;
    box-shadow: 18px 16px 22px #08302d44;
    min-height: 700px;
    // max-height: 700px;
    position: absolute;
  }

  .imagePanel {
    border-radius: 1rem;
    background: url("https://storage.googleapis.com/pzone-site-images/home_handshake.webp")
      center no-repeat;
    left: -1.5rem;
    top: 0;
    z-index: 6;
    height: 700px;
    background-color: #f3f3f3;
    overflow: hidden;
    background-size: cover;
    border-radius: 2rem;
  }
  .textPanel {
    width: 52%;
    margin-left: auto;
    position: relative;
    border-radius: 2rem;
    min-height: 700px;
    top: 3rem;
    right: -1rem;
    color: var(--text-light);
    z-index: 5;
    text-align: justify;
    background-image: var(--bg-gradient-secondary);
    padding: 5rem 7.5rem;
    .headText {
      font-family: "PT Sans";
      text-transform: uppercase;
      padding-bottom: 1.5rem;
      text-align:start;
      h2 {
        font-size: 2.3rem;
        font-weight: 400;
      }
      h1 {
        font-size: 3.5rem;
        font-weight: 900;
      }
    }
  }
}

@media screen and (max-width: 1366px) {
  .whoWeAreWrapper {
    .textPanel {
      padding: 3.75rem;
      .headText {
        h1 {
          font-size: 2.5rem;
        }
        h2 {
          font-size: 1.45rem;
        }
      }
    }
  }
}

@media screen and (max-width: 1200px) {
  .whoWeAreWrapper {
    margin: 0 1rem;
    border-radius: 1rem;
    box-shadow: 0 0 35px #20202056;
    // overflow: hidden;
    .imagePanel,
    .textPanel {
      position: relative;
      left: 0;
      top: 0;
      width: 100%;
      border-radius: 0;
    }
    .textPanel {
      min-height: 400px;
      max-height: 100%;
      padding: 1rem;
      .headText {
        padding-bottom: 1rem;
        h2 {
          font-size: 1.8rem;
        }
        h1 {
          font-size: 2.25rem;
        }
      }
    }
  }
}

@media screen and (max-width: 767px) {
  .whoWeAreWrapper {
    margin: 0 1rem;
    border-radius: 1rem;
    box-shadow: 0 0 35px #20202056;
    // overflow: hidden;

    .textPanel {
      position: relative;
      left: 0;
      top: 0;
      width: 100%;
      border-radius: 0.5rem;
    }
    .imagePanel {
      display: none;
    }
    .textPanel {
      min-height: 400px;
      max-height: 100%;
      padding: 1rem;
      .headText {
        padding-bottom: 1rem;
        h2 {
          font-size: 1.25rem;
        }
        h1 {
          font-size: 2rem;
        }
      }
    }
  }
}
