@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,400;0,500;0,600;0,700;0,800;0,900;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");
:root {
    overflow-x: hidden;
}

body {
    margin: auto !important;
    padding: 0 !important;
    box-sizing: content-box !important;
    font-family: "Montserrat", -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell",
        "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif !important;
    -webkit-font-smoothing: antialiased !important;
    -moz-osx-font-smoothing: grayscale !important;
    position: relative !important;
    overflow-x: hidden !important;
    max-width: 1920px;
    background: #c9c9c96e !important;
}

.landingImage {
    max-height: 45vh;
    width: 100vw;
    overflow: hidden;
    position: relative;
    z-index: 2;
    img {
        position: relative;
        max-width: 100%;
    }
    h1 {
        font-weight: 700;
        text-transform: uppercase;
        color: var(--text-light);
        padding-bottom: 2.5rem;
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        z-index: 70;
        text-align: center;
    }
}

.headDescription {
    display: flex;
    margin-top: 5rem;
    .col1 {
        width: 40%;
    }
    .col2 {
        width: 60%;
    }
}
.descriptionWrapper {
    width: 75%;
    margin: auto;
    .description {
        margin-top: 3.5rem;
        text-align: justify;
    }
}
.titleWrapper {
    font-size: 2rem;
    font-weight: 700;
    text-transform: uppercase;
    white-space: pre-wrap;
    span.left,
    span.right {
        color: var(--text-secondary);
        position: relative;
    }

    span.left {
        &::before {
            content: "";
            position: absolute;
            right: 0;
            top: 110%;
            width: 100vw;
            z-index: 1;
            border: 1px solid var(--bg-secondary);
        }
        &::after {
            content: "";
            position: absolute;
            top: 98%;
            right: 0;
            height: 12px;
            z-index: 2;
            width: 12px;
            background: var(--bg-secondary-light);
            border-radius: 50%;
        }
    }

    span.right {
        &::after {
            content: "";
            position: absolute;
            left: 0;
            top: 115%;
            width: 100vw;
            z-index: 1;
            border: 1px solid var(--bg-secondary);
        }
        &::before {
            content: "";
            position: absolute;
            top: 103%;
            left: 0;
            height: 12px;
            width: 12px;
            z-index: 3;
            background: var(--bg-secondary-light);
            border-radius: 50%;
        }
    }
}

.descImage {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    img {
        height: 25rem;
    }
}

@media screen and (max-width: 1366px) {
    .descImage img {
        height: 20rem;
    }
}

@media screen and (max-width: 1024px) {
    .descImage {
        display: none;
        img {
            height: 15rem;
        }
    }
    .headDescription {
        flex-direction: column;
        .col1,
        .col2 {
            width: 100%;
        }
    }
}
@media screen and (max-width: 767px) {
    .titleWrapper {
        font-size: 1.25rem;
    }
    .descriptionWrapper {
        .description {
            font-size: 1rem;
        }
    }
    .landingImage {
        max-height: 50vh;
        h1 {
            padding-bottom: 0.25rem;
            font-size: 1.5rem;
        }
    }
}
