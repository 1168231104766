.countrySelect {
  padding: 1.5rem 0;
}
.countryVal {
  display: inline-flex;
  align-items: center;
}
.search {
  padding: 0.25rem 0;
  position: sticky;
  top: 0;
  background-color: white;
}
.formSelect {
  .formSelectWrapper {
    position: relative;
    .selectBtn {
      background: transparent;
      outline: none;
      display: inline-block;
      text-align: left;
      border: none;
      text-decoration: none;
      color: #1b1b1b;
      border-bottom: solid 1px rgba(0, 0, 0, 0.1);
      width: 100%;
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
      span.placeholder {
        color: #6c6c6c;
      }
      &:focus {
        border-bottom: solid 1px var(--bg-secondary);
      }
      .val:not(:last-child):after {
        content: "| ";
        color: var(--bg-secondary);
      }
    }
  }
}

.optionsWrapper {
  position: absolute;
  left: 0;
  top: 100%;
  right: 0;
  z-index: 15;
  max-height: 0;
  transition: all 0.3s ease-in-out;
  overflow: hidden;
  background: white;
  box-shadow: 0 0 5px #b4b4b4b2;
  border-bottom-left-radius: 1rem;
  border-bottom-right-radius: 1rem;
  opacity: 0.5;
  min-width: 7.5rem;
  overflow: auto;
  &.show {
    max-height: 300px;
    opacity: 1;
  }
  .options {
    padding: 0.5rem 1rem;

    button.link {
      padding: 0.5rem;
      display: flex;
      justify-content: space-between;
      align-items: center;
      text-align: left;
      text-decoration: none;
      border: none;
      width: 100%;
      color: rgb(99, 98, 98);
      background: transparent;
      font-size: 0.9rem;
      border-radius: 0.5rem;
      outline: none;

      .check {
        color: #15bcc2;
      }
      &:not(:last-child) {
        border-bottom: thin solid #d8d8d83f;
      }
      &:hover,
      &:focus {
        color: var(--bg-secondary);
        background: #15bcc238;
        cursor: pointer;
      }
    }
  }
}
