.PageDataAnalytics {
  display: block;
  margin: 0 auto;
}
.contentWrapper {
  width: 90%;
  margin: auto;
  padding: 4rem 0;
}
.offeringWrapper {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  column-gap: 1rem;
  row-gap: 1rem;
  padding: 1rem 0;
  .offer {
    border: 2px solid var(--bg-secondary);
    padding: 0.5rem;
    font-size: 1.25rem;
    width: 100%;
    display: flex;
    justify-content: center;
    min-height: 80px;
    text-align: center;
    align-items: center;
    font-weight: 600;
  }
}
.offersContainer {
  width: 90%;
  margin: auto;
}
.listContainer {
  padding: 0.5rem 0;
  span {
    padding: 0 0.5rem;
  }
  img {
    height: 1rem;
    width: 1.25rem;
  }
}

@media screen and (max-width: 1540px) {
  .descImg img {
    height: 20rem !important;
  }
}

@media screen and (max-width: 1250px) {
  .daRow {
    flex-direction: column !important;
    .daCol1 {
      justify-content: center !important;
      width: 100% !important;
    }
    .daCol2 {
      width: 100% !important;
    }
  }
  .descImg {
    width: 100%;

    justify-content: center !important;
  }
}

@media screen and (max-width: 1024px) {
  .offeringWrapper {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
  }
}

@media screen and (max-width: 767px) {
  .offeringWrapper {
    grid-template-columns: 1fr;
  }
}
