.contentPart {
  padding: 5rem 0;
  width: 90%;
  margin: auto;
}
.solutionGrid {
  display: grid;
  grid-template-columns: 1fr 1fr;
  padding: 2rem 0;
  column-gap: 5rem;
  .solCard {
    background: var(--bg-ternary);
    padding: 1rem;
    position: relative;
    box-shadow: 2px 2px 4px #4545454f;
    &::after {
      content: "";
      position: absolute;
      top: 100%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 25%;
      border: 2px solid var(--bg-secondary-light);
    }
  }
}
.highlighted {
  color: var(--text-secondary);
  font-weight: 600;
}
.servicesGrid {
  display: grid;
  grid-template-columns: 2fr 1fr 2fr;
  column-gap: 1rem;
  justify-items: center;
}
.chatImage {
  height: 16rem;
}

.serviceWrapper {
  margin-top: 1rem;

  p.service {
    display: flex;
    align-items: flex-start;
    padding: 0.75rem 0;
    text-align: left;
    &.right {
      justify-content: end;
      text-align: right;
    }
  }
  img {
    height: 1.25rem;
    margin: 0.25rem 0.5rem 0.5rem 0.5rem;
  }
}

.reasonsWrapper {
  .title {
    border-bottom: 2px dotted #9ca7a7;
  }

  .reason {
    display: flex;
    padding: 0.5rem;
    img {
      height: 1.25rem;
      vertical-align: middle;
      margin-right: 1rem;
    }
  }
}

.textGrid {
  display: grid;
  grid-template-columns: 1fr 1fr;
  text-align: justify;
  column-gap: 3rem;
}
.advWrapper {
  h6 {
    padding: 1rem;
  }
  .aCard {
    background: var(--bg-ternary);
    padding: 1rem;
    box-shadow: 2px 2px 4px #4545454f;
    display: flex;
    align-items: flex-start;
    text-align: justify;
    margin: 1rem 0;
    img {
      height: 1.5rem;
      margin: 0.25rem 0.75rem 0 0;
    }
  }
}
.note {
  font-size: 1.25rem;
  color: var(--text-secondary);
  font-weight: 600;
  padding: 1rem 0;
  text-align: center;
}
@media screen and (max-width: 992px) {
  .solutionGrid {
    grid-template-columns: 1fr;
    row-gap: 1rem;
  }
  .servicesGrid {
    grid-template-columns: 1fr 1fr;
    .col2 {
      display: none;
    }
  }

  .contentPart {
    width: 75%;
  }
}

.headDescription {
  display: flex;
  margin-top: 5rem;
  .col1 {
    width: 45%;
  }
  .col2 {
    width: 55%;
  }
}

@media screen and (max-width: 1024px) {
  .headDescription {
    flex-direction: column;
    .col1,
    .col2 {
      width: 100%;
    }
  }
}
@media screen and (max-width: 768px) {
  .contentPart {
    width: 90%;
  }
  .servicesGrid {
    grid-template-columns: 1fr;
  }
  .textGrid {
    grid-template-columns: 1fr;
  }
  .advWrapper {
    h6 {
      text-align: center;
    }
  }
}
