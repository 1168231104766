.explorationWrapper {
  padding: 1rem 3.5rem;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  // display: flex;
  // align-items: center;
  // flex-direction: column;

  .bodyText {
    margin-top: 1.5rem;
    color: var(--text-light);
    width: 30rem;
    font-size: 1.15rem;
  }
}
.videoWrapper {
  &:not(:first-child) {
    padding: 1.5rem 0.5rem;
  }

  .video {
    max-width: 100%;
    max-height: 100%;
  }
}
.processingWrapper {
  padding: 1rem 0;
  visibility: hidden;
  position: relative;
  &.show {
    visibility: visible;
  }
}

@media screen and (max-width: 1366px) {
  .processingWrapper {
    text-align: center;
  }
  .videoWrapper {
    .video {
      height: 4rem;
    }
  }
}

@media screen and (max-width: 767px) {
  .explorationWrapper {
    padding: 1rem;
    text-align: center;
    .headText {
      font-size: 1.675rem;
      span:last-child {
        font-size: 2rem;
      }
    }
    .bodyText {
      text-align: justify;
      width: auto;
      font-size: 1rem;
    }
  }
}

.productsButton {
  position: absolute;
  top: 80%;
  left: 0;
  right: 0;
  // bottom: 0;
  margin-bottom: 5rem;
}

.animatedButton {
  padding: 0 2rem;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1rem;
  position: absolute;
  width: 500px;
  top: 0;
  left:0;
  right: 0;
  transform: translate(15%, 10%);
}

.buttonDiv{
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 0;
 
  transform: translate(20%, 10%);
  height: 100px;
  width: 450px;
}
.buttonImage{
  height: 100%;
  width: 100%;
}
// @media screen and (max-width: 1600px) {
//   .buttonDiv {
//     transform: translate(10%, -100%);

//   }
// }

@media screen and (max-width: 1366px) {
  .buttonDiv {
    transform: translate(10%, 20%);
  }
}

// @media screen and (max-width: 1200px) {
//   .buttonDiv {
//     transform: translate(65%, -120%);;
//   }
// }

@media screen and (max-width: 1024px) {
  .buttonDiv {
    transform: translate(10%, -10%);;
  }
}

// @media screen and (max-width: 768px) {
//   .buttonDiv {
//     transform: translate(10%, -120%);

//   }
// }
@media screen and (max-width: 576px) {
  .buttonDiv {
    transform: translate(2%, -20%);    
  }
  .buttonImage{
  height: 70%;
  width: 70%;
}
}
@media screen and (max-width: 480px) {
  .buttonDiv {
    transform: translate(-10%, -20%);    
  }
  .buttonImage{
  height: 70%;
  width: 70%;
}
}
@media screen and (max-width: 375px) {
  .buttonDiv {
    transform: translate(-15%, -25%);    
  }
  .buttonImage{
    height: 50%;
    width: 50%;
  }
}
@media screen and (max-width: 320px) {
  .buttonDiv {
    transform: translate(-20%, -25%);    
  }
  .buttonImage{
    height: 50%;
    width: 50%;
  }
}

