.video {
  height: 10rem;
}

@media screen and (max-width: 1366px) {
  .video {
    height: 7rem;
  }
}

@media screen and (max-width: 600px) {
  .video {
    height: 5rem;
  }
}

@media screen and (max-width: 400px) {
  .video {
    height: 4rem;
  }
}
