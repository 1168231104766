.PageTermsOfUse {
  font-size: 0.9rem;
  text-align: justify;
  h1 {
    font-weight: 700;
    padding: 2rem 0;
    font-weight: 700;
    background: var(--bg-secondary);
    color: var(--text-light);
  }
  ol {
    padding-top: 1.5rem;
    li {
      padding-bottom: 1rem;
    }
  }
}
