.menuLinkWrapper {
  // display: flex;
  .menuLink {
    display: flex;
    justify-content: space-between;
    padding: 1rem;
    transition: 0.3s;
    color: var(--text-primary);
    &.active {
      background: #d8e4e4;
    }
  }
  .menuOptions {
    background: #f1f8f8;
    display: flex;
    flex-direction: column;
    overflow: hidden;
    height: 0;

    &.open {
      height: auto;
    }
    // padding: 0.25rem 1rem;   
    .optionLink {
      color: black;
      padding: 0.5rem 1rem;
      display: inline-block;
      text-decoration: none;
      transition: 0.3s;
      &:hover {
        background: var(--bg-secondary);
        color: var(--text-light);
      }
    }
  }
}
