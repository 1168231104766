.ETSUseCases {
  width: 90%;
  margin: auto;
  margin-top: 5rem;
}
.title {
  text-align: center;
  font-weight: 600;
}

.useCaseGrid {
  display: grid;
  grid-template-columns: 1fr 1fr;
  padding: 3rem 0;
}

.useCaseTable {
  width: 100%;
  thead > tr > th {
    background-color: var(--bg-secondary-light);
    color: var(--text-light);
    border: 2px solid var(--bg-secondary);
    padding: 0.4rem 1rem;
    text-align: center;
    // font-size: 1.25rem;
    font-weight: 700;
  }
  tbody > tr > td {
    background-color: #f7f7f7;
    border-right: 2px solid var(--bg-secondary);
    border-bottom: 2px solid rgba(74, 129, 127, 0.548);
    &:first-child {
      border-left: 2px solid var(--bg-secondary);
    }
    padding: 0.25rem;
    text-align: center;

    font-size: 0.85rem;
  }
}

.useCaseDescription {
  padding: 0 3rem;
  text-align: justify;
  font-size: 1.25rem;
}

@media screen and (max-width: 991px) {
  .useCaseGrid {
    grid-template-columns: 1fr;
  }
  .useCaseDescription {
    padding: 3rem 0;
  }
}
