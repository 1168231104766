@import url("https://fonts.googleapis.com/css2?family=Advent+Pro:wght@500;700&display=swap");
.whatWeDeoWrapper {
    padding-bottom: 5rem;
}

.titleWrapper {
    position: absolute;
    top: -3.25rem;
    left: 0;
    right: 0;
    text-align: center;
    font-size: 4rem;
    font-weight: 700;
    font-family: "Advent Pro", "Courier New", Courier, monospace;
    position: relative;
    color: rgb(57, 59, 83);

    .titleX {
        background: var(--bg-primary);
        display: inline-block;
        transform: skewX(-20deg);
        border-left: 2px solid var(--color-primary);
        border-right: 2px solid var(--color-primary);
        .titleY {
            display: inline-block;
            padding: 0 1rem;
            margin: 0 2px;
            text-transform: uppercase;
            border-left: 2px solid var(--color-primary);
            border-right: 2px solid var(--color-primary);
        }
        .title {
            transform: skewX(20deg);
        }
    }
}

@media screen and (max-width: 767px) {
    .titleWrapper {
        font-size: 2rem;
        top: -1.5rem;
    }
    .whatWeDeoWrapper {
        padding: 0.5rem;
    }
}
