.PageFixedOddsManage {
  .videoWrapper {
    // position: absolute !important;
    top: 0;
    left: 0;
    right: 0;
    height: 55vh;
    position: relative;
    overflow: hidden;
    z-index: 1;
    .video {
      position: absolute;
      height: 55rem;
      z-index: -1;
      width: 100%;
      object-position: 0 -200px;
      object-fit: cover;
    }
  }
}

.pageWrapper {
  margin: auto;
  width: 90%;
  position: relative;

}

.imgDescriptionWrp {
  display: grid;
  grid-template-columns: auto auto;
  text-align: justify;
  padding-bottom: 0.5rem;
}
.headSection {
  top: -6.5rem;
  z-index: 10;
  position: relative;
  .headDescription {
    img {
      float: left;
    }
    padding: 1rem 1rem 0.4rem 1rem;
    box-shadow: 0 2px 30px #b8b8b855;
    border-radius: 0.5rem;
    background: white;
    min-height: 170px;
    text-align: justify;
  }
  h2 {
    text-align: center;
    font-weight: 700;
    font-size: 3rem;
    color: var(--text-light);
    text-shadow: 1px 2px 3px rgb(20, 20, 20);
  }
  &::after {
    content: "";
    position: absolute;
    border: 3px solid var(--bg-secondary-light);
    top: 100%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 25%;
  }
}




.buttonDiv{
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 10;
 
  transform: translate(137%, -70%);
  height: 100px;
  width: 450px;
}
.buttonImage{
  height: 100%;
  width: 100%;
}
@media screen and (max-width: 1600px) {
  .buttonDiv {
    transform: translate(105%, -100%);

  }
}
  
@media screen and (max-width:1440px) {
  .buttonDiv {
    transform: translate(92%, -68%);
  }
}

@media screen and (max-width: 1366px) {
  .buttonDiv {
    transform: translate(85%, -130%);
  }
}

@media screen and (max-width: 1200px) {
  .buttonDiv {
    transform: translate(65%, -120%);;
  }
}

@media screen and (max-width: 1024px) {
  .buttonDiv {
    transform: translate(40%, -120%);;
  }
}

@media screen and (max-width: 768px) {
  .buttonDiv {
    transform: translate(10%, -120%);

  }
}
@media screen and (max-width: 576px) {
  .buttonDiv {
    transform: translate(7%, -120%);    
  }
  .buttonImage{
  height: 70%;
  width: 70%;
}

  
}
@media screen and (max-width: 375px) {
  .buttonDiv {
    transform: translate(11%, -120%);    
  }
  .buttonImage{
    height: 50%;
    width: 50%;
  }
}
@media screen and (max-width: 320px) {
  .buttonDiv {
    transform: translate(6%, -120%);    
  }
  .buttonImage{
    height: 50%;
    width: 50%;
  }
}

.button_82_pushable:focus:not(:focus-visible) {
  outline: none;
}

@media screen and (max-width: 1366px) {
  .headSection {
    top: -10.5rem;
    h2 {
      font-size: 2rem;
    }
  }
}