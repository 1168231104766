@import "../../../styles/mixins.scss";
.menuLinkWrapper {
    position: relative;
    .menuLink {
        @include menu-link;
        color: var(--text-secondary);
        &.light {
            color: var(--text-light);
        }
    }
    .menuOptions {
        overflow: hidden;
        border-radius: 0.5rem;
        box-shadow: 0px 3px 15px #2020202c;
        width: 15rem;
        background: var(--bg-primary);
        position: absolute;
        top: 100%;
        left: 50%;
        transform: translateY(0%) translateX(-50%);
        .optionLink {
            @include link-default(1.05rem);
            padding: 0.5rem 1rem;
            flex-shrink: 0;
            display: block;
            &:hover {
                background: var(--bg-secondary);
                color: var(--text-light);
                cursor: pointer;
            }
            &:not(:last-child)::after {
                content: "";
                border-bottom: 1px solid rgba(45, 202, 168, 0.205);
                width: 100%;
                display: block;
                padding: 3px;
            }
        }
    }
}
