.pzRow {
    display: flex;
    align-items: center;
    margin-bottom: 10rem;
    &.left {
        flex-direction: row;
    }
    &.right {
        flex-direction: row-reverse;
    }
    .pzCol {
        padding: 0 1.5rem;
        text-align: justify;
        width: 100%;
        display: flex;
        justify-content: center;
        .colBody {
            text-align: justify;
            font-size: 1.15rem;
            width: 85%;
            margin: auto;
            .title {
                color: var(--text-secondary);
                font-weight: 700;
                padding-bottom: 1.5rem;
                text-align: left;
            }
        }
    }
}

.linkWrapper {
    display: flex;
    .link {
        &.right {
            padding-left: 1rem;
            margin-left: auto;
            position: relative;
            &::before {
                content: "";
                position: absolute;
                right: 100%;
                border: 1px solid var(--bg-secondary);
                width: 100vw;
                top: 50%;
                z-index: 1;
            }
            a::before {
                content: "";
                position: absolute;
                right: 100%;
                height: 10px;
                width: 10px;
                z-index: 2;
                border-radius: 50%;
                background: var(--bg-secondary-light);
                top: 40%;
            }
        }
        &.left {
            position: relative;
            padding-right: 1rem;
            &::after {
                content: "";
                position: absolute;
                left: 100%;
                border: 1px solid var(--bg-secondary);
                width: 100vw;
                top: 50%;
                z-index: 1;
            }
            a::after {
                content: "";
                position: absolute;
                left: 100%;
                height: 10px;
                width: 10px;
                z-index: 2;
                border-radius: 50%;
                background: var(--bg-secondary-light);
                top: 40%;
            }
        }
        a {
            background: var(--bg-secondary);
            color: var(--text-light);
            font-weight: 600;
            display: inline-block;
            border-radius: 2rem;
            text-decoration: none;
            padding: 0.5rem 1.5rem;
            transition: 0.35s;
            &:hover {
                cursor: pointer;
                background: var(--bg-secondary-light);
            }
        }
    }
}

@mixin pzRowCol {
    .pzRow {
        &.left,
        &.right {
            flex-direction: column;
        }
    }
}

@mixin imageSize($size: 30rem) {
    .defImage {
        height: $size;
    }
}

@include imageSize(30rem);
@media screen and (max-width: 1600px) {
    @include imageSize(25rem);
}

@media screen and (max-width: 1366px) {
    @include imageSize(20rem);
}

@media screen and (max-width: 1024px) {
    @include pzRowCol();
}

@media screen and (max-width: 767px) {
    @include pzRowCol();
    .pzRow {
        margin-bottom: 5rem;
        .pzCol {
            .colBody {
                width: 100%;
                font-size: 0.9rem;
                .title {
                    text-align: center !important;
                    font-size: 1.5rem;
                    padding-bottom: 0;
                    padding-top: 0.5rem;
                }
            }
        }
    }
}

.note {
    color: rgb(10, 82, 100);
    font-style: italic;
    padding: 0 0.5rem 1rem 0.5rem;
    display: flex;
    font-size: 1rem;
    line-height: 25px;
    span:first-child {
        padding-right: 0.5rem;
        color: var(--bg-secondary);
    }
}

@media screen and (max-width: 480px) {
    @include pzRowCol();
    @include imageSize(12rem);
}
