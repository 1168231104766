.videoContainer {
  position: relative;
  z-index: -1;
  margin-right: 3rem;
  .video {
    border: 1.75rem solid black;
    border-radius: 2vw;
    height: 100%;
    width: 100%;
    z-index: -1;
  }
}

@media screen and (max-width: 767px) {
  .videoContainer {
    margin: 0.5rem 1rem;
    .video {
      border: 0.75rem solid black;
    }
  }
}
