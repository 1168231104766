@import "../../styles/mixins.scss";

.menuWrapper {
  // padding: 1.75rem 0;
  display: flex;
  .menuLink {
    @include menu-link;
    color: var(--text-secondary);

    &.light {
      color: white;
    }
  }
}

.sidebarWrapper {
  .closeBtn {
    position: fixed;
    top: 10px;
    right: var(--sidebar-width);
    border-top-left-radius: 0.35rem;
    border-bottom-left-radius: 0.35rem;
    padding: 0.5rem 0.25rem;
    font-size: 1.2rem;
    // color: var(--text-light);
    background: var(--bg-primary);
    border: none;
    z-index: 13;
    box-shadow: -3px 0px 35px -1px #18181871;

    font-weight: 600;
  }
  .sidebarMask {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: #1498b946;
    z-index: 5;
  }
  .sidebar {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    width: var(--sidebar-width);
    background: var(--bg-primary);
    box-shadow: -2px 0px 35px #00000099;
    z-index: 12;
    overflow-y: auto;
    .sidebarBody {
      padding: 0 1rem;
      position: relative;

      .sidebarLogo {
        padding: 1rem 0 1.5rem 0;
        border-bottom: thin solid var(--bg-secondary);
        img {
          height: 2.5rem;
          // width: 70%;
        }
      }
      .sidebarMenuBtn {
        background: transparent;
        padding: 1rem;
        border: none;
        width: 100%;
        display: flex;
        transition: 0.3s;
        color: var(--text-dark);
        text-decoration: none;
        &:hover {
          background: var(--bg-secondary);
          color: var(--text-light);
        }
      }
    }
  }
}

.bars {
  padding: 0 1rem;
  font-size: 1.2rem;
  color: var(--text-secondary);
  background: transparent;
  border: none;
  text-align: right;
  &.light {
    color: white;
  }
}

@media screen and (max-width: 767px) {
  .menuWrapper {
    .menuLink {
      font-size: 0.9rem !important;
    }
  }
}
@media screen and (min-width: 768px) and (max-width: 1365px) {
  .bars {
    // color: var(--bg-secondary);
    font-size: 1.75rem;
  }
}

@media screen and (max-width: 300px) {
  .sidebarWrapper {
    .closeBtn {
      right: 0;
      border-radius: 0;
      background: transparent;
      box-shadow: none;
    }
  }
}
