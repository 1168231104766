.numbersWrapper {
    .numbers {
        display: flex;
        align-items: center;
        a {
            display: inline-block;
            padding: 0.1rem 0.5rem;
            color: rgb(48, 48, 48);
            text-decoration: none;
            transition: 0.3s;
            font-size: 0.9rem;
            &:hover {
                color: var(--text-secondary);
            }
        }
    }
}

.phoneDropWrapper {
    position: relative;
    display: none;
    button {
        background: transparent;
        border: none;
        font-size: 1.25rem;
        margin-right: 1rem;
        color: var(--text-secondary);
        &.light {
            color: white;
        }
    }
    .dropItemsWrapper {
        position: absolute;
        border-radius: 0.5rem;
        box-shadow: 0px 3px 15px #2020202c;
        width: 15rem;
        background: var(--bg-primary);
        top: 110%;
        right: 1rem;
        .phoneDropItems {
            .numbers {
                display: flex;
                align-items: center;
                padding: 0 0.5rem;
                a {
                    display: inline-block;
                    color: rgb(48, 48, 48);
                    padding-left: 0.5rem;
                    text-decoration: none;
                    transition: 0.3s;
                    font-size: 0.9rem;
                    &:hover {
                        color: var(--text-secondary);
                    }
                }
            }
        }
    }
}

@media screen and (max-width: 767px) {
    .phoneDropWrapper {
        button {
            // color: var(--text-dark) !important;
            font-size: 1rem;
        }
    }
}

@media screen and (max-width: 1365px) {
    .numbersWrapper {
        display: none;
    }
    .phoneDropWrapper {
        display: inline-block;
    }
}
