@import "/src/styles/mixins.scss";

.fixedHeader {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 300;
  background: var(--bg-gradient-secondary);
  padding: 0 0.5rem 0rem 2rem;
  box-shadow: 0 2px 5px #2b2a2a;
  .header {
    @include flex-between;
    .logoWrapper {
      .image {
        height: 55px;
      }
    }
  }
}
