.pageWrapper {
  min-height: 100vh;
  .coverHeader {
    height: 40vh;
    background: white;
    background-size: cover;
    background-attachment: fixed;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    h1 {
      color: var(--text-secondary);
      font-weight: 600;
      font-size: 5rem;
      font-style: italic;
      text-shadow: 1px 2px 2px #323e3f;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .coverFooter {
      /* background: white; */
      margin-top: auto;
      /* border-left: 10px solid #bd2f2fab; */
      //   border-left: 30px solid transparent;
      //   border-right: 30px solid transparent;
      //   border-bottom: 40px solid white;
      //   position: relative;
    }
  }
}
.tabBtnWrapper {
  position: relative;
  &::before,
  &::after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    z-index: 1;
    bottom: 0;
    background: white;
    transform: skewX(45deg);
  }
  &::after {
    transform: skewX(-45deg);
    left: -35px;
  }
  &::before {
    right: -35px;
  }
  .tabHeadBtn {
    background: transparent;
    text-transform: uppercase;
    border: none;
    border-bottom: 4px solid transparent;
    padding: 0.25rem 1rem;
    position: relative;
    z-index: 2;
    &.activeTab {
      border-bottom: 4px solid var(--bg-secondary);
    }
  }
}

.teamCardWrapper {
  margin-top: 6rem;
  display: flex;
  justify-content: center;
  .teamCard {
    border-radius: 1rem;
    padding: 0.5rem;
    margin: 0 1rem;
    border: 1px solid #003b4233;
    // background: linear-gradient(to top, #43bdbbc2, #dddddd, #fff);
    background: var(--bg-ternary);
    .cardBody {
      text-align: center;
      .name {
        display: block;
        margin: 0;
        padding: 1.5rem 0 0 0;
        font-size: 1.5rem;
        font-weight: 600;
        text-transform: uppercase;
      }
      .designation {
        font-style: italic;
        color: rgb(83, 83, 83);
      }
    }
  }
}
.profileImage {
  object-fit: cover;
  height: 100%;
  width: 100%;
  border-radius: 1rem;
}

.teamWrapper {
  text-align: center;
  margin-top: 3rem;
  .typeTitle {
    position: relative;
    span {
      background: white;
      padding: 0 0.5rem;
      text-transform: capitalize;
      color: var(--text-secondary);
      font-style: italic;
      font-size: 0.9rem;
    }
    &::after {
      content: "";
      position: absolute;
      border: 1px solid rgb(38, 156, 146);
      left: 0;
      top: 50%;
      right: 0;
      z-index: -1;
    }
  }
  .typeBody {
    .profileCardsContainer {
      display: flex;
      padding: 1rem;
      justify-content: center;
      align-items: center;
      .allMemberCard {
        margin: 1rem 1rem 0 1rem;
        text-align: center;
        .cardImage {
          height: 15rem;
          width: 15rem;
          border-radius: 50%;
          overflow: hidden;
          box-shadow: 3px 3px 5px #31313191;
          transition: 0.3s;
          transform: scale(0.9);
          &:hover {
            box-shadow: 0px 0px 15px 5px #79e7cc2f, 0px 0px 20px 15px #268d732f, 0px 0px 5px 5px #268d7391;
            transform: scale(1);
          }
          img {
            height: 100%;
            width: 100%;
            object-fit: cover;
            object-position: 0 0;
          }
        }
        .cardBody {
          padding-top: 1rem;
          .name {
            font-size: 1.25rem;
          }
          .designation {
            font-style: italic;
            color: var(--text-secondary);
          }
        }
      }
    }
  }
}
