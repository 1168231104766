@import "./styles/mixins.scss";

// For router transitions
.fade-enter {
  opacity: 0;
  z-index: 1;
}

.fade-enter.fade-enter-active {
  opacity: 1;
  transition: opacity 250ms ease-in;
}

.flip-card {
  background-color: transparent;
  width: 300px;
  height: 300px;
  perspective: 1000px;
}

.flip-card-inner {
  position: relative;
  border-radius: 1rem;
  width: 100%;
  height: 100%;
  text-align: center;
  transition: transform 0.6s;
  transform-style: preserve-3d;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
}

.flip-card:hover .flip-card-inner {
  transform: rotateY(180deg);
}

.flip-card-front,
.flip-card-back {
  position: absolute;
  border-radius: var(--profile-card-radius);
  width: 100%;
  height: 100%;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
}

.flip-card-front {
  background-color: transparent;
  color: black;
}

.flip-card-back {
  background: linear-gradient(
    218deg,
    var(--bg-secondary-light),
    var(--bg-secondary)
  );
  color: #ffffff;
  transform: rotateY(180deg);
  padding: 1rem 0;
}

.back {
  // position: relative;
  background: white;
}

.ank {
  background: linear-gradient(
    to left,
    #eef9fc 0%,
    #87d7e6 8%,
    #62a3b4 92%,
    #1f8091 100%
  );
}

.ashva {
  background: linear-gradient(
    to left,
    rgb(255, 255, 255) 0%,
    #dd7c86 8%,
    #d33c4c 92%,
    rgb(65, 51, 95) 100%
  ) !important
}

.notFoundPage {
  height: 60vh;
  @include flex(column);
  font-style: italic;
  color: rgb(201, 201, 201);
  h1 {
    font-weight: 600;
  }
  img {
    height: 300px;
    width: 300px;
  }
}

.topButton {
  position: fixed;
  z-index: 884;
  bottom: 3rem;
  left: 3rem;
  background: linear-gradient(-45deg, #048eff, #54c7c1);
  border-radius: 3rem;
  // padding: 0 0.5rem;
  color: white;
  min-height: 2.25rem;
  width: 2.25rem;
  border: none;
  span:last-child {
    padding: 0 0.5rem;
  }
  // @include flex;
  white-space: nowrap;
  // overflow: hidden;
  // text-overflow: clip;
  overflow: hidden;
  box-shadow: 2px 2px 4px #0475a5b7, -2px -2px 2px #565aa466;
  transition: width 0.35s ease;
  &:hover {
    width: auto;
  }
}

.wrapper {
  height: 2.5rem;
  position: fixed;
  width: auto;
  left: -3rem;
  bottom: -3rem;
  z-index: 1000;
  display: inline-flex;
  align-items: center;
  transition: all 0.3s;
  visibility: hidden;
  .topIcon {
    font-size: 1.5rem;
    color: #fff;
    cursor: pointer;
    font-size: 1.5rem;
    background: #2794a5;
    border-radius: 20px;
    height: 100%;
    width: 2.5rem;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    z-index: 2;
    box-shadow: -2px 3px 4px #2f324465;
  }

  .text {
    white-space: nowrap;
    position: relative;
    box-shadow: 2px 1px 4px #2f324465;
    z-index: 1;
    height: 100%;
    width: 0;
    color: #fff;
    overflow: hidden;
    transition: 0.3s all ease;
    background: #2794a5;
    height: 100%;
    display: flex;
    align-items: center;
    border-top-right-radius: 20px;
    border-bottom-right-radius: 20px;
    margin-left: 20px;
    padding-left: 20px;
    cursor: pointer;
  }

  &.show {
    left: 3rem;
    bottom: 3rem;
    visibility: visible;
  }
  &:hover .text {
    width: 100%;
    padding-right: 20px;
  }

  animation: show;
}

.highlight {
  color: var(--bg-secondary);
  font-weight: 600;
}

@keyframes show {
  0% {
    bottom: 0;
  }
  100% {
    bottom: 3rem;
  }
}
