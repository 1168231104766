:root {
  --color-primary: #008281;
  --color-primary-light: #06c7c6;
  --color-black: #000000;
  --color-white: #ffffff;
  --color-light-gray: #f6f6f6;
  --color-gray: #5757577c;
  --color-red: #db3d3d;
  //   Social colors
  --color-facebook: rgb(100, 143, 230);
  --color-google: #3cba54;
  --color-twitter: #1da1f2;
  --color-instagram: #f56040;
  --color-linkedIn: #6bc4ee;
  // Layouting sizes
  --sidebar-width: 280px;
  --profile-card-radius: 1rem;
  // ChatBot Size
  
}

.pzDefault {
  --bg-primary: var(--color-white);
  --bg-secondary: var(--color-primary);
  --bg-secondary-light: var(--color-primary-light);
  --bg-gradient-secondary: linear-gradient(to bottom, #06c7c6, #008281);
  --bg-ternary: var(--color-light-gray);
  // Text
  --text-primary: var(--color-black);
  --text-secondary: var(--color-primary);
  --text-light: var(--color-white);
  --text-dark: var(--color-black);
  --text-danger: var(--color-red);
  // Table
  --table-cell-light: #e9f8f8;
  --table-cell-dark: #cfeaea;
  --hover-text-secondary: #0f5858;
  --hover-link-light: #ebf82f;
  --border-thick-gray: 2px solid var(--color-gray);
}
