.pageWrapper {
  margin: auto;
}
.usageTitle {
  padding: 1rem 0;
  font-size: 2.5rem;
  font-weight: 600;
  text-align: center;
}
.usageWrapper {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  justify-items: center;

  .usageCard {
    background: #dffdfd;
    width: 75%;
    box-shadow: 2px 2px 4px #5252529c;
  }
}

