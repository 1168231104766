.wrapper_nine {
  background-color: #09715c00;
  width: 100%;
  text-align: center;
}

.comingSoonText {
  text-transform: uppercase;
  font-weight: 600;
}

.ankColor {
  color: var(--color-primary) !important;
}
.rotate span {
  text-align: center;
  font-weight: 600;
  font-size: 3rem;
  color: #000000;
  display: inline-flex;
  animation: rotate 2s infinite;
}
@keyframes rotate {
  0%,
  75% {
    transform: rotateY(360deg);
  }
}
.nine span:nth-of-type(1) {
  animation-delay: 0.2s;
}
.nine span:nth-of-type(2) {
  animation-delay: 0.4s;
}
.nine span:nth-of-type(3) {
  animation-delay: 0.6s;
}
.nine span:nth-of-type(4) {
  animation-delay: 0.8s;
}
.nine span:nth-of-type(5) {
  animation-delay: 1s;
}
.nine span:nth-of-type(6) {
  animation-delay: 1.2s;
}
.nine span:nth-of-type(7) {
  animation-delay: 1.4s;
}
.nine span:nth-of-type(8) {
  animation-delay: 1.6s;
}
.nine span:nth-of-type(9) {
  animation-delay: 1.8s;
}
.nine span:nth-of-type(10) {
  animation-delay: 2s;
}
.nine span:nth-of-type(11) {
  animation-delay: 2.2s;
}
.nine span:nth-of-type(12) {
  animation-delay: 2.4s;
}
