.TechList {
  width: 90%;
  margin: auto;
  margin-top: 5rem;
}
.contentWrapper {
  margin-top: 2rem;
}

.categoryWrapper {
  margin: 1rem 0;
  .title {
    display: flex;
    border-bottom: 1px solid #247a6854;
    justify-content: center;
    font-weight: 600;
    padding: 0.35rem 0;
    align-items: center;
    img {
      margin-right: 0.57rem;
      height: 1rem;
    }
  }
}
.gridWrapper {
  display: flex;
  justify-content: center;
}
.techsGrid {
  display: inline-grid;
  grid-template-columns: repeat(4, 1fr);
  column-gap: 1.5rem;
  padding: 2rem 1rem;
  justify-items: center;
  .techCard {
    box-shadow: 2px 2px 6px #4545454f;
    display: flex;
    padding: 0 1.5rem;
    align-items: center;
    justify-content: center;
    min-height: 9rem;
    // width: 18rem;
    img {
      height: 7rem;
    }
  }
}

@media screen and (max-width: 1200px) {
  .techsGrid {
    .techCard {
      img {
        height: 5rem;
      }
    }
  }
}

@media screen and (max-width: 991px) {
  .techsGrid {
    grid-template-columns: 1fr 1fr;
    row-gap: 1.5rem;
  }
}

@media screen and (max-width: 768px) {
  .techsGrid {
    grid-template-columns: 1fr 1fr;
  }
}

@media screen and (max-width: 480px) {
  .techsGrid {
    display: grid;
    width: 100%;
    grid-template-columns: 1fr;
    .techCard {
      width: 100%;
    }
  }
}
