.PageWagerOptimization {
  display: block;
  margin: 0 auto;
}
.useCasesWrapper {
  margin-top: 5rem;
}
.useCasesGrid {
  padding: 2.5rem 0;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  .useCase {
    padding: 0 1rem;
    text-align: center;
    &:first-child {
      border-right: 1px solid var(--bg-secondary-light);
    }
    &:last-child {
      border-left: 1px solid var(--bg-secondary-light);
    }
  }
}

.solutionGrid {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  padding: 3rem 0 5rem 0;
  column-gap: 3rem;
  .solCard {
    background: var(--bg-ternary);
    padding: 1rem 3rem;
    position: relative;
    box-shadow: 2px 2px 4px #4545454f;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    .advImg {
      height: 5rem;
      margin: auto;
      margin-bottom: 1.5rem;
    }
    &:nth-child(odd) {
      top: 2rem;
    }
    &::after {
      content: "";
      position: absolute;
      top: 100%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 25%;
      border: 2px solid var(--bg-secondary-light);
    }
  }
}
.headDescription {
  display: flex;
  margin-top: 5rem;
  .col1 {
      width: 45%;
  }
  .col2 {
      width: 55%;
  }
}

@media screen and (max-width: 1024px) {

    .headDescription {
        flex-direction: column;
        .col1,
        .col2 {
            width: 100%;
        }
    }
}



.applicablesWrapper {
  padding-top: 5rem;
  width: 90%;
  margin: auto;

  .listContainer {
    padding: 0.5rem 0;
    span {
      padding-left: 1rem;
    }
    img {
      height: 1rem;
      width: 1.25rem;
    }
  }
}
@media screen and (max-width: 1024px) {
  .solutionGrid {
    grid-template-columns: 1fr;
    row-gap: 1.5rem;
    .solCard {
      &:nth-child(odd) {
        top: 0;
      }
    }
  }
}
