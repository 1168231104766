.msRow {
  display: flex;
  align-items: center;
  margin-bottom: 5rem;
  margin-top: 5rem;
  .msCol {
    padding: 0 1.5rem;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  &.left {
    flex-direction: row;
  }
  &.right {
    flex-direction: row-reverse;
  }
}

.videoWrapper {
  position: relative;

  .video {
    height: 420px;
    position: relative;
    z-index: 12;
    border: 1rem solid black;
    border-radius: 1.5rem;
    background: white;
    &.left {
      box-shadow: 1rem 1rem 1rem #97979793;
    }
    &.right {
      box-shadow: -1rem 1rem 1rem #97979793;
    }
  }
  .videoBorder {
    position: absolute;
    height: 450px;
    width: 100%;
    border: 0.5rem solid var(--bg-secondary);
    border-radius: 1.25rem;
    z-index: 11;
    top: 20px;
    &.left {
      left: -30px;
    }
    &.right {
      right: -30px;
    }
  }
}

.contentWrapper {
  width: 100%;
  .content {
    text-align: justify;
    margin-top: 1.5rem;
    font-size: 1.15rem;
  }
}
.title1 {
  color: var(--text-primary) !important;
  padding-right: 0.5rem;
}
.title2 {
  color: var(--text-secondary);
  padding-right: 0.5rem;
}

@media screen and (max-width: 1600px) {
  .videoWrapper {
    .video {
      height: 380px;
    }
    .videoBorder {
      height: 400px;
    }
  }
}

@media screen and (max-width: 1366px) {
  .videoWrapper {
    .video {
      height: 300px;
    }
    .videoBorder {
      height: 320px;
    }
  }
  .msRow {
    margin-top: 1rem;
  }
}

@media screen and (max-width: 1024px) {
  .videoWrapper {
    .video {
      height: 250px;
    }
    .videoBorder {
      height: 270px;
    }
  }
  .contentWrapper {
    .titleWrapper {
      font-size: 1.25rem;
    }
    .content {
      font-size: 1rem;
    }
  }
}

@media screen and (max-width: 1023px) {
  .msRow {
    margin-bottom: 1.5rem;
    &.left,
    &.right {
      flex-direction: column-reverse;
    }
  }
  .videoWrapper {
    width: 100%;
    .video {
      height: 100%;
      width: 100%;
    }
    .videoBorder {
      display: none;
    }
  }
  .contentWrapper {
    width: 100%;
  }
}
@media screen and (max-width: 375px) {
  .msRow {
    margin-top: 0;
  }
}
