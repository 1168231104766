.w-90 {
    width: 90%;
    margin: auto;
}

.mTop5 {
    margin-top: 5rem;
}

.my2 {
    margin: 2rem 0;
}
.cookieContainer {
    background: linear-gradient(45deg, #ffffff, #ffffff) !important;
    padding: 0.5rem 1rem !important;
    color: black !important;
    box-shadow: 0 -5px 12px #1f49474b;
    font-size: 0.8rem !important;
}
.cookieBtns {
    border-radius: 0.5rem !important;
    margin: 0 0.5rem 0 0 !important;
    font-size: 1rem;
    &.acceptBtn {
        background: orange !important;
        padding: 0.5rem 1.25rem !important;
    }
    &.cancelBtn {
        background: rgba(34, 43, 63, 0) !important;
        color: black !important;
    }
}
