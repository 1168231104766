.blobLeft {
  position: absolute;
  top: -5px;
  left: -5px;
  z-index: 1;
  img {
    height: 768px;
    width: 800px;
  }
}

.mainViewWrapper {
  display: flex;
  flex-direction: column;
  min-height: calc(100vh - 115px);
  .exploreBtnWrapper {
    padding: 1rem;
    margin-top: auto;
    text-align: center;
    height: auto;
    opacity: 1;
    transition: all 0.3s;
    button {
      margin: auto;
      background: rgba(224, 224, 224, 0.534);
      // box-shadow: 0px 2px 0px 1px #8b8b8b63;
      border: none;
      color: #2b8d8f;
      font-weight: 600;
      text-transform: uppercase;
      padding: 0.5rem 1.5rem;
      border-radius: 0.5rem;
      display: flex;
      align-items: center;
      .icon {
        padding-left: 5px;
      }
    }
    &.hide {
      visibility: hidden;
      opacity: 0;
    }
  }
}
.infoWrapper {
  display: flex;
  padding-top: 3rem;
  position: relative;
  z-index: 2;
  .exploreWrapper {
    width: 40%;
  }
  .videoWrapper {
    width: 60%;
  }
}

.whoWeAreWrapper {
  min-height: 100vh;
  overflow: hidden;
}

.whatWeDeoWrapper {
  margin-top: 10rem;
  background: var(--bg-ternary);
  position: relative;
  min-height: 100vh;
  border-top: 2px solid var(--color-primary);
}

@media screen and (max-width: 1670px) {
  .infoWrapper {
    .exploreWrapper,
    .videoWrapper {
      width: 50%;
    }
  }
}

@media screen and (max-width: 1200px) {
  .whoWeAreWrapper {
    min-height: auto;
    padding-bottom: 5rem;
  }
  .infoWrapper {
    display: block;
    // align-items: center;
    min-height: auto;
    padding-top: 0.5rem;
    .exploreWrapper {
      // background-image: var(--bg-gradient-secondary);
      width: 100%;
    }
    .videoWrapper {
      margin: 0;
      width: 100%;
      padding: 3rem;
      margin-top: 3rem;
    }
  }
}

@media screen and (max-width: 767px) {
  .blobLeft {
    display: none;
    img {
      height: 100%;
      width: 100%;
    }
  }
  .infoWrapper {
    display: block;
    // align-items: center;
    min-height: auto;
    padding-top: 0.5rem;
    .exploreWrapper {
      // background-image: var(--bg-gradient-secondary);
      width: 92%;
      border-radius: 0.5rem;
      margin: auto;
    }
    .videoWrapper {
      margin: auto;
      width: 100%;
      padding: 0;
      margin-top: 3rem;
    }
  }
}


@media screen and (max-width:375px) {
  .infoWrapper .exploreWrapper .videoWrapper {
    margin: auto;
    width: 100%;
    padding: 0;
    margin-top: 4rem !important
  }

  
}