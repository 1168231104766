.footerWrapper {
  //   min-height: 400px;
  //   background: url("../../../../assets/images/footer.svg") bottom no-repeat;
  background-color: var(--bg-secondary);
  position: relative;
  //   display: flex;
  //   align-items: flex-end;
  .footerContent {
    display: flex;
    justify-content: space-between;
    .logoSocialWrapper {
      flex-grow: 1;
      padding: 2rem;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      .logo {
        img {
          height: 100%;
          width: 100%;
        }
      }
      .socials {
        a {
          background: transparent;
          border: none;
          color: white;
          font-size: 2rem;
          // height: 3rem;
          // margin: 0 0.5rem;
          border-radius: 50%;
          // width: 3rem;
          transition: 0.3s;
          display: inline-flex;
          padding: 1rem;
          justify-content: center;
          align-items: center;
          transform: scale(0.9);
          &:hover {
            color: white;
            transform: scale(1);
          }
          &.fb:hover {
            background: var(--color-facebook);
          }
          &.gp:hover {
            background: var(--color-google);
          }
          &.twt:hover {
            background: var(--color-twitter);
          }
          &.linkedIn:hover {
            background: var(--color-linkedIn);
          }
        }
      }
    }
    .infoGrid {
      padding: 2rem 0;
      flex-grow: 2;
      color: var(--text-light);
      display: flex;
      justify-content: space-around;
      .gridHeader {
        font-weight: 700;
        font-size: 1.25rem;
        display: flex;
        align-items: center;
      }
      .hours {
        padding-bottom: 1.5rem;
      }
      .quickLink {
        color: white;
        text-decoration: none;
        transition: 0.3s;
        display: inline-block;
        padding: 0.5rem;
        &:hover {
          color: var(--hover-link-light);
        }
      }
    }
  }
}
.footerCopyright {
  background: #046e6e;
  border-top: thin solid rgba(120, 69, 119, 0.151);
  color: white;
  font-size: 0.9rem;
  padding: 0.5rem;
}
.linkWrapper {
  display: flex;
  align-items: center;
  .contactLink {
    color: var(--text-light);
    text-decoration: none;
    padding: 0 0.5rem;
  }
}

.locateBtn {
  background: #ffffff;
  border: none;
  border-radius: 0.5rem;
  transition: 0.6s;
  display: flex;
  align-items: center;
  padding: 0.5rem 1rem;
  text-decoration: none;

  color: black;
  span {
    margin: 0 0.5rem;
  }
  &:hover {
    background-color: rgb(32, 218, 209);
    color: black;
  }
}

@media screen and (max-width: 767px) {
  .footerWrapper {
    .footerContent {
      flex-direction: column;
      padding-bottom: 6rem;
      .logoSocialWrapper {
        padding: 0.5rem 0;
        .logo {
          img {
            height: 4rem;
          }
        }
        .socials {
          button {
            padding: 0.345rem 0.565rem;
            font-size: 1.15rem;
          }
        }
      }
      .infoGrid {
        flex-direction: column;
        // justify-content: flex-start;
        // align-items: fe;
        padding: 0 2rem;
        .linksWrapper {
          display: flex;
          flex-wrap: wrap;
          padding-bottom: 1rem;
          .quickLink {
            flex-shrink: 0;
            flex-grow: 1;
            padding: 0;
            // padding: 0.25rem 0.5rem;
            &::after {
              content: "";
              border: 1px solid rgb(209, 209, 209);
              margin: 0 0.5rem;
            }
          }
        }
      }
    }
  }
}
