.formInput {
  padding: 1.5rem 0;
  .inputWrapper {
    position: relative;
  }
}
$primary: var(--text-secondary);

input.inpBox {
  font-size: 16px;
  border: none;
  border-bottom: solid 1px rgba(0, 0, 0, 0.1);
  background: #fff;
  width: 100%;
  box-sizing: border-box;
  transition: all 0.3s linear;
  color: #000;
  font-weight: 400;
  -webkit-appearance: none;
  &:focus {
    border-bottom: solid 1px $primary;
    outline: 0;
    box-shadow: 0 2px 6px -8px rgba($primary, 0.45);
  }
  &.inpError {
    border-bottom: solid 1px rgba(248, 15, 15, 0.781);
  }
}

.floatingLabelWrapper {
  position: relative;

  label.inpLabel {
    position: absolute;
    top: calc(50% - 1.35rem);
    left: 0;
    opacity: 0;
    transition: all 0.3s ease;
    pointer-events: none;
  }
  input.inpBox:not(:placeholder-shown) {
    // padding: 28px 0px 12px 0px;
  }
  input.inpBox:not(:placeholder-shown) + label.inpLabel {
    transform: translateY(-10px);
    opacity: 0.7;
    font-size: 0.85rem;
  }
}

.error {
  font-size: 0.85rem;
  color: #fc1616;
  position: absolute;
  z-index: 5;
  top: 100%;
  left: 0;
}

.phoneInput {
  display: grid;
  grid-template-columns: 5rem auto;
  column-gap: 0.5rem;
}
